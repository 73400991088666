(function() {
  const $header = $('#site-header');

  $('[data-scroll-to]').on('click', function(e) {
    // Cancel the native event
    e.preventDefault();

    // Get the target element; do nothing if it does not exist
    const targetSelector = this.dataset.scrollTo;
    const targetElement = document.querySelector(targetSelector);
    if (!targetElement) return;

    // Calculate the target scroll position
    const isSideNav = $header.css('position') === 'fixed';
    const headerHeight = isSideNav ? 0 : $header.outerHeight();
    const padding = isSideNav ? $header[0].offsetTop : headerHeight;
    const targetScrollPosition = targetElement.offsetTop - headerHeight - padding;

    // Scroll the window to the target position
    window.scrollTo({top: targetScrollPosition, behavior: 'smooth'});
  });
})();
