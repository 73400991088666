(function($) {
  // Define a JQuery extension to shuffle DOM elements
  // Inspired by: http://www.yelotofu.com/2008/08/jquery-shuffle-plugin/
  $.fn.shuffleChildren = function() {
    return this.each(function() {
      const $items = $(this).children();
      return ($items.length) ?
        $(this).html(shuffle($items)) :
        this;
    });
  };

  /**
   * Shuffle an array.
   *
   * @param {Array<any>} arr
   * @return {Array<any>} The shuffled array.
   */
  function shuffle(arr) {
    for (
      let j, x, i = arr.length; i;
      j = parseInt(Math.random() * i),
      x = arr[--i], arr[i] = arr[j], arr[j] = x
    );
    return arr;
  };
})(jQuery);

(function() {
  const $carousel = $('.page-banner-carousel');
  const $slideImages = $carousel.find('.carousel-item img');
  const $currentSlideNumber = $carousel.find('.current-slide-number');

  // Shuffle the carousel items so they are displayed in a random order on each page load
  // After shuffling, update the display index of each item and reset the active slide
  $carousel.find('.carousel-item')
      .removeClass('active')
      .parent().shuffleChildren()
      .children().each(function(index, element) {
        $(element).find('.slide-index').text(index + 1);
      })
      .first().addClass('active');

  // Mark adjacent slide images as eager loading now, and each time the current slide changes
  setEagerImages($carousel.find('.carousel-item.active'));
  $carousel.on('slide.bs.carousel', (e) => setEagerImages($(e.relatedTarget)));

  // Set the slide image sources now and whenever the window is resized (debounced)
  // The source image is selected by finding the closest aspect ratio to the <img> element
  setCarouselImageSources();
  let resizeDebounceTimerId;
  $(window).on('resize', () => {
    clearTimeout(resizeDebounceTimerId);
    resizeDebounceTimerId = setTimeout(setCarouselImageSources, 100);
  });

  // Attach an event handler to update the slide number half way through the slide animation
  $carousel.on('slide.bs.carousel', (event) => {
    setTimeout(() => $currentSlideNumber.text('0' + (event.to + 1)), 300);
  });

  // Initialize the carousel
  $carousel.carousel({ride: 'carousel'});

  /**
   * Set loading='eager' on slide images adjacent to the current slide
   *
   * @param {JQuery<HTMLDivElement>} $currentSlide The current slide.
   */
  function setEagerImages($currentSlide) {
    // Get the next slide
    const $next = $currentSlide.is(':last-child') ?
      $carousel.find('.carousel-item').first() :
      $currentSlide.next('.carousel-item');

    // Get the previous slide
    const $prev = $currentSlide.is(':first-child') ?
      $carousel.find('.carousel-item').last() :
      $currentSlide.prev('.carousel-item');

    // Set loading='eager' on both slide images
    $next.add($prev).find('img').attr('loading', 'eager');
  }

  /**
   * Set the `src` attribute for each slide image in the carousel.
   */
  function setCarouselImageSources() {
    $slideImages.each((index, imgElem) => setSlideImageSrc(imgElem));
  }

  /**
   * Set the `src` attribute on the specified image element based on its aspect ratio.
   *
   * @param {HTMLImageElement} imgElem
   *
   * @typedef {Object} ImageSource
   * @property {String} src The image source path.
   * @property {Number} width The image width.
   * @property {Number} height The image hight.
   * @property {Number} ratio The image aspect ratio.
   */
  function setSlideImageSrc(imgElem) {
    // If the image is not shown, temporarily display it, but invisibly and without affecting the document flow
    const $image = $(imgElem);
    const isHidden = !$image.is(':visible');
    if (isHidden) $image.closest('.carousel-item').addClass('active-invisible');

    // Get the target width, height and aspect ratio
    const targetWidth = imgElem.clientWidth;
    const targetHeight = imgElem.clientHeight;
    const targetRatio = targetWidth / targetHeight;

    // Hide the element again if we were showing it temporarily
    $image.closest('.carousel-item').removeClass('active-invisible');

    /**
     * Parse information on all the possible image sources
     * @type {ImageSource[]}
     */
    const imgSources = JSON.parse(imgElem.dataset.srcList);

    // Get the closest matching aspect ratio
    const closestRatio = imgSources
        .map((src) => src.ratio)
        .reduce(function(closestRatio, currentRatio) {
          const isCurrentCloser = Math.abs(currentRatio - targetRatio) < Math.abs(closestRatio - targetRatio);
          return isCurrentCloser ? currentRatio : closestRatio;
        }, imgSources[0].ratio);

    // Get all images that have the closest ratio (within a certain tolerance)
    const minRatio = closestRatio - 0.01;
    const maxRatio = closestRatio + 0.01;
    const filteredSources = imgSources.filter((src) => src.ratio >= minRatio && src.ratio <= maxRatio);

    // Sort images by size (smallest first)
    // NOTE: Only need to use width since all remaining images have the same aspect ratio
    filteredSources.sort((a, b) => a.width - b.width);

    // Get the smallest image that is larger than the target size
    // If no image is large enough, use the largest image
    const bestMatch = filteredSources.find((src) => src.width >= targetWidth) ??
      filteredSources.slice(-1)[0];

    // Assign the source image (assume there is a jpeg and webp version of the image)
    imgElem.src = bestMatch.src;
    $image.siblings('source[type="image/jpeg"]').attr('srcset', bestMatch.src);
    $image.siblings('source[type="image/webp"]').attr('srcset', bestMatch.src.replace('.jpeg', '.webp'));
  }
})();
