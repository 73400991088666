/**
 * Enhance '.sticky-top' components to add a '.stuck-top' class when the sticky behaviour is active
 *
 * NOTE: This is a fairly naive implementation that will only work for elements stuck to the top of the screen. For a
 * more comprehensive solution see: https://developers.google.com/web/updates/2017/09/sticky-headers
 */
(function() {
  const $stickyElements = $('.sticky-top');

  // Create a placeholder element
  $stickyElements.after($('<div class="sticky-top-placeholder"></div>'));

  $(window).on('scroll resize', debounce(function() {
    const isScrolled = window.scrollY > 50;
    $stickyElements.toggleClass('stuck-top', isScrolled);
  }, 10));

  /**
   * Returns a function, that, as long as it continues to be invoked, will not be triggered. The function will be
   * called on both the leading and trailing edges of the timeout.
   *
   * Based on: https://davidwalsh.name/javascript-debounce-function
   *
   * @param {*} func The function to execute when the timeout elapses.
   * @param {*} wait The timeout duration.
   * @return {Function} The generated function.
   */
  function debounce(func, wait) {
    let timeout;
    return function(...args) {
      // Define a function to call after the timeout
      const context = this;
      const later = function() {
        timeout = null;
        func.apply(context, args);
      };

      // Call now if the timeout has not been set yet
      if (timeout === null) func.apply(context, args);

      // Reset the timeout to wait another 'wait' milliseconds
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }
})();
